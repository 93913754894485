
h2 {
  font-family: monospace;
  font-size: 30px;
}

/* p {
  font-family: monospace;
  font-size: 15px;
  width: 31%;
  align-self: center;
} */
section {
  padding: 1.75%;
}

.project-image {
  width: 25%;
  height: auto;
  padding: 2%;
}

.deployed-link {
  background-color: #63AAE3;
  color: navy;
  border-radius: 10px;
  text-decoration: none;
  padding: 0.5%;
  margin: .25%;
}

.deployed-link:hover {
  background-color: #2583d0;
  color: white;
}

#mission-ctrl {
  background-color: #61E5D6;
}

#taped-it {
  background-color: #E6C74C;
}

#bank-test {
  background-color: #5A8AD8;
}
