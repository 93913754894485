@import url(https://fonts.googleapis.com/css2?family=Just+Me+Again+Down+Here&display=swap);
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.link {
  padding: 2%;
  font-family: sans-serif;
  color: blue;
}

.intro-text {
  padding: 5%;
  text-align: center;
}
img {
  width: 5%;
  height: auto
}
.about-text {
  font-family: sans-serif;
  text-align: center;
  padding: 5%;
}

h2 {
  font-family: monospace;
  font-size: 30px;
}

/* p {
  font-family: monospace;
  font-size: 15px;
  width: 31%;
  align-self: center;
} */
section {
  padding: 1.75%;
}

.project-image {
  width: 25%;
  height: auto;
  padding: 2%;
}

.deployed-link {
  background-color: #63AAE3;
  color: navy;
  border-radius: 10px;
  text-decoration: none;
  padding: 0.5%;
  margin: .25%;
}

.deployed-link:hover {
  background-color: #2583d0;
  color: white;
}

#mission-ctrl {
  background-color: #61E5D6;
}

#taped-it {
  background-color: #E6C74C;
}

#bank-test {
  background-color: #5A8AD8;
}


.banner {
  background-color: #FF707A;
  padding: 1.75%;
  margin-top: 0.75%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.nav-link {
  font-family: monospace;
  color: white;
  font-size: 2vh;
  text-decoration: none;
}

.nav-link:hover {
  color: #29F5BB;
}

#initials {
  font-family: 'Just Me Again Down Here', cursive;
  text-align: right;
  color: white;
  font-size: 6vh;
}

.title-text {
  font-family: sans-serif;
  text-align: center;
  padding: 0.75%;
}
